<template>
    <div v-if="awaitingResponse" class="loader"></div>
    <div v-if="!awaitingResponse" class="bon-api-results">
        <h2 v-if="substitutes.length">
            Suggested substitutes:
        </h2>
        <ul v-if="substitutes.length" id="suggested-substitutes">
            <li v-for="(substitute, index) in substitutes" :key="index">
                <span class="original-ingredient">{{ substitute.original_ingredient }}</span>
                <Octicon v-if="substitute.replacement_ingredient" icon="arrow-switch" :width="26" ariaLabel="can be replaced with" />
                <span class="replacement-ingredient" v-if="substitute.replacement_ingredient">{{ substitute.replacement_ingredient }}</span>
            </li>
        </ul>
        <div v-if="graph.series.length" id="composition-info">
            <h2>Substitutes Composition Info:</h2>
            <apexchart v-if="graph.series.length" type="donut" width="380" :options="graph.chartOptions" :series="graph.series"></apexchart>
        </div>
        
        <div v-if="fetchError" id="fetch-error">
            <h2>Oh dear!</h2>
            {{ fetchError }} <router-link :to="{ name: 'Swap' }">Go back</router-link>
        </div>
    </div>
</template>
<script>
    import BonAPIFoodService from '@/services/BonAPIService.js';
    import Octicon from '@/components/Octicon.vue';
    import VueApexCharts from 'vue3-apexcharts';

    export default {
        name: 'APIResults',
        components: {
            Octicon,
            apexchart: VueApexCharts
        },
        data() {
            return {
                substitutes: [],
                compositionInfo: null,
                awaitingResponse: true,
                fetchError: '',
                graph: {
                    series: [],
                    chartOptions: {
                        chart: {
                            width: 300,
                            type: 'donut',
                        },
                        fill: {
                            type: 'gradient',
                        },
                        responsive: [{
                            breakpoint: 400,
                            options: {
                                chart: {
                                    width: 280
                                },
                                legend: {
                                    position: 'bottom'
                                }
                            }
                        }]
                    },
                }
            }
        },
        mounted() {
            if (this.ingredientsList.filter(el => el).length === 0) {
                this.awaitingResponse = false;
                this.fetchError = 'You haven\'t entered any ingredients, please go back and try again.';
            } else {
                this.fetchError = '';
                this.awaitingResponse = true;
                // Fetch the substitutes from the API.
                BonAPIFoodService.getAlternatives(this.ingredientsList.filter(el => el), this.hasAllergies ? this.allergiesAndIntolerances : [], this.hasDietaryPreference ? this.dietaryPreference : '')
                    .then(res => {
                        if (typeof res.data === 'string') {
                            return JSON.parse(res.data);
                        } else {
                            return res.data;
                        }
                    })
                    .then(data => {
                        this.awaitingResponse = false;
                        if (typeof data.response_code == 'undefined' && typeof data.status_code == 'undefined') {
                            // Unexpected response returned from the API.
                            throw 'Bon API response data returned without a response_code attribute.';
                        }
                        switch (data.response_code || data.status_code) {
                            case 200:
                                if (data.response.updated_ingredients.filter(e => { return e.indexOf('(previously: ') !== -1 }).length) {
                                    const updated_ingredients = data.response.updated_ingredients;
                                    let ingredients_output = [];
                                    for (var ingredient_line of updated_ingredients) {
                                        let original = '';
                                        let replacement = '';
                                        if (ingredient_line.lastIndexOf(')') == ingredient_line.length - 1) {
                                            original = ingredient_line.slice(0, -1).split(' (previously: ');
                                            original = original[1];
                                            replacement = ingredient_line.replace(' (previously: ' + original + ')', '');
                                        } else {
                                            original = ingredient_line;
                                        }
                                        ingredients_output.push({
                                            original_ingredient: original,
                                            replacement_ingredient: replacement
                                        });
                                    }
                                    this.substitutes = ingredients_output;
                                    this.compositionInfo = data.response.composition_info.overall;
                                    this.graph.series = [];
                                    if (this.totalCarbohydrates && this.totalProtein && this.totalFat) {
                                        this.graph.series.push(parseFloat(this.totalCarbohydrates));
                                        this.graph.series.push(parseFloat(this.totalProtein));
                                        this.graph.series.push(parseFloat(this.totalFat));
                                        let totalEnergy = this.totalEnergy;
                                        this.graph.chartOptions = {
                                            ...this.graph.chartOptions,
                                            ...{
                                                labels: ['Carbs (g): ' + this.totalCarbohydrates, 'Protein (g): ' + this.totalProtein, 'Fat (g): ' + this.totalFat],
                                                plotOptions: {
                                                    pie: {
                                                        donut: {
                                                            labels: {
                                                                show: true,
                                                                name: {
                                                                    show: true,
                                                                    fontSize: '16px',
                                                                    fontWeight: 'bold',
                                                                    formatter (value) {
                                                                        return value.split(':')[0]
                                                                    }
                                                                },
                                                                value: {
                                                                    show: true,
                                                                    fontSize: '16px',
                                                                    formatter (value) {
                                                                        return value + 'g';
                                                                    }
                                                                },
                                                                total: {
                                                                    show: true,
                                                                    label: 'Energy',
                                                                    color: '#373d3f',
                                                                    fontSize: '16px',
                                                                    fontWeight: 'bold',
                                                                    formatter () {
                                                                        return totalEnergy;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    this.substitutes = [];
                                    this.fetchError = 'Substitutes unavailable, please try again.';
                                }
                                break;
                            case 404:
                                this.substitutes = [];
                                this.fetchError = 'Unable to find matches for the ingredients you entered, please try again.';
                                break;
                            case 500:
                                this.substitutes = [];
                                this.fetchError = 'Unable to fetch substitutes, please try again.';
                                break;
                            case 429:
                                this.substitutes = [];
                                // This case would obviously need to be handled better/send alerts to upgrade the service package level, etc.
                                this.fetchError = 'Unable to fetch substitutes, please try again tomorrow.';
                                break;
                            default:
                                break;
                        }
                    })
                    .catch((err) => {
                        // console.log(err)
                        // Something went wrong during the request/processing of it - clear the substitutes and display an error message.
                        this.awaitingResponse = false;
                        this.substitutes = [];
                        this.fetchError = 'Unable to fetch substitutes, please try again.';
                    });
            }
        },
        computed: {
            ingredientsList() {
                return this.$store.state.originalIngredients;
            },
            hasAllergies() {
                return this.$store.state.hasAllergies;
            },
            allergiesAndIntolerances() {
                return this.$store.state.allergiesAndIntolerances;
            },
            hasDietaryPreference() {
                return this.$store.state.hasDietaryPreference;
            },
            dietaryPreference() {
                return this.$store.state.dietaryPreference;
            },
            totalCarbohydrates() {
                let returnString = '';
                if (this.compositionInfo && this.compositionInfo.carbohydrate && this.compositionInfo.carbohydrate.value) {
                    returnString = this.compositionInfo.carbohydrate.value.toFixed(1) + this.compositionInfo.carbohydrate.unit;
                }
                return returnString;
            },
            totalProtein() {
                let returnString = '';
                if (this.compositionInfo && this.compositionInfo.protein && this.compositionInfo.protein.value) {
                    returnString = this.compositionInfo.protein.value.toFixed(1) + this.compositionInfo.protein.unit;
                }
                return returnString;
            },
            totalFat() {
                let returnString = '';
                if (this.compositionInfo && this.compositionInfo.total_fat && this.compositionInfo.total_fat.value) {
                    returnString = this.compositionInfo.total_fat.value.toFixed(1) + this.compositionInfo.total_fat.unit;
                }
                return returnString;
            },
            totalEnergy() {
                let returnString = '';
                if (this.compositionInfo && this.compositionInfo.energy && this.compositionInfo.energy.value) {
                    returnString = this.compositionInfo.energy.value.toFixed(1) + this.compositionInfo.energy.unit;
                }
                return returnString;
            }
        }
    }
</script>
<style scoped lang="scss">
    .bon-api-results {
        background: #fff;
        padding: 1em 1.5em;
    }
    h2 {
        color: #000;
        margin-top: 0;
        padding-top: 0;
    }
    ul#suggested-substitutes {
        list-style-type: none;
        padding: 0;
        li {
            color: #000;
            display: block;
            margin: 0;
            // margin: 0.5em 0;
            padding: 0.2em 0.5em;
            text-align: left;
            &:nth-child(odd) {
                background: rgba(0,0,0,0.1);
            }
            &:nth-child(even) {
                background: rgba(0,0,0,0.2);
            }
            .original-ingredient, .replacement-ingredient {
                box-sizing: border-box;
                display: inline-block;
                line-height: 26px;
                width: 45%;
                vertical-align: middle;
                text-align: right;
                padding: 0 10px;
            }
            .arrow-switch {
                display: inline-block;
                text-align: center;
                width: 10%;
                line-height: 26px;
                height: 26px;
                vertical-align: middle;
                svg {
                    vertical-align: middle;
                }
            }
            .replacement-ingredient {
                // font-size: 0.85em;
                // line-height: 1.2em;
                // font-style: italic;
                font-weight: bold;
                text-align: left;
            }
        }
    }
    #composition-info {
        background: #fff;
        padding: 1em;
    }
    #fetch-error {
        color: #000;
        a {
            color: #0000bb;
        }
    }
</style>