import axios from 'axios'

export default {
  getAlternatives(ingredients, allergies, diet) {
    const apiClient = axios.create({
      baseURL: 'https://bon-api-2.jimsrepos.workers.dev/',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const queryParams = {
      composition: 'energy,carbohydrate,protein,total_fat',
      nutrition_claims: 'true',
      // seasonality: 'eur', // Seasonality seems to cause a 500 error when included.
      language: 'en'
    }
    if (allergies.length) {
      queryParams['allergies'] = allergies.join(',');
    }
    if (diet) {
      queryParams['diet'] = diet;
    }
    if (allergies == '' && diet == '') {
      queryParams['replace_only_unsuitable_ingredients'] = 'False';
    }
    const data = {
      ingredients: ingredients
    };

    return apiClient.post('/ingredient/alternatives/', JSON.stringify(data), { params: queryParams });
  }
}